import * as React from "react";
import "@fontsource/dm-sans";
import "./cg.sass";
import Layout from "../components/Layout";
import { Link } from "gatsby";

// markup
const Cg = () => {
  return (
    <Layout>
      <div id="cgv-holder">
        <h2>CONDITIONS GÉNÉRALES</h2>
        <h5>1. Champ d’application</h5>
        <p>
          <span>1.1</span> La plateforme Legalify.ch (ci-après : la{" "}
          <span className="fat">« Plateforme »</span>) est détenue et
          administrée par Legalify Sàrl, Rue des Alpes 15, 1201 Genève (ci-après
          : <span className="fat">« Legalify.ch »</span>).
        </p>
        <p>
          <span>1.2</span> En accédant à la Plateforme, vous acceptez le contenu
          et l’application des présentes conditions générales (ci-après : les{" "}
          <span className="fat">« Conditions générales »</span>) et de notre
          politique de confidentialité, disponible sur la Plateforme (ci-après :
          la <Link to="/pdc">« Politique de confidentialité »</Link>), qui en
          fait partie intégrante. Celles-ci s’appliquent à tous les services,
          conseils ou informations fournis par le biais de la Plateforme ou par
          les employés, les représentants ou les auxiliaires de Legalify.
        </p>
        <p>
          <span>1.3</span> Lorsque la relation entre l’utilisateur de la
          Plateforme (ci-après : <span className="fat">« l’Utilisateur </span>»)
          et Legalify est définie par des règles distinctes, les Conditions
          générales et la <Link to="/pdc">Politique de confidentialité</Link>{" "}
          demeurent applicables, sous réserve de dispositions contraires
          prévalant sur elles.
        </p>
        <h5>2. Prestations de Legalify</h5>
        <p>
          <span>2.1</span> Divers services et prestations sont proposés à
          travers la Plateforme, notamment la constitution et l’enregistrement
          de sociétés, les inscriptions et les modifications au registre du
          commerce, le téléchargement de documents juridiques et de contrats, la
          fourniture de conseils juridiques par téléphone ou en présentiel ou
          encore la conclusion d’abonnements mensuels pour la fourniture de
          conseils et de services juridiques (ci-après ensemble : les{" "}
          <span className="fat">« Services »</span>).
        </p>
        <p>
          <span>2.2</span> L’étendue des Services est déterminée, pour chaque
          cas, par les circonstances, notamment par les prestations requises ou
          commandées par l’Utilisateur, ainsi que par les instructions et
          informations données par ce dernier, pour autant que celles-ci soient
          acceptées par Legalify.
        </p>
        <p>
          <span>2.3</span> Legalify s’engage à fournir les Services avec le soin
          et la diligence nécessaires et peut prendre toutes les mesures jugées
          utiles ou nécessaires dans ce cadre, selon sa propre appréciation.
        </p>
        <p>
          <span>2.4</span> Legalify est libre de s’adjoindre les services de
          tous employés, représentants, auxiliaires ou prestataires de services
          externes jugés utiles ou nécessaires, notamment des études d’avocats
          ou de notaires. Lorsqu’il est fait appel à un prestataire de services
          externe, l’Utilisateur pourra, selon les cas, être en relation
          contractuelle directe avec ce prestataire de services externe,
          notamment avec l’étude d’avocats GVA law, à Genève, avec qui Legalify
          collabore.
        </p>
        <p>
          <span>2.5</span> Legalify n’assume aucune responsabilité pour les
          services fournis par des prestataires de services externes, quels
          qu’ils soient.
        </p>
        <p>
          <span>2.6</span> Legalify se base sur les instructions, les
          informations et les documents donnés par l’Utilisateur, qui sont
          censés être exacts et complets. Sauf demande expresse de la part de
          l’Utilisateur, Legalify ne procède pas à leur vérification.
          L’Utilisateur reconnaît expressément que Legalify est en droit de se
          fier à ses instructions, informations et documents, afin de lui
          fournir les Services.
        </p>
        <p>
          <span>2.7</span> Legalify se réserve le droit de refuser de fournir
          les Services si cela est jugé nécessaire, selon sa propre
          appréciation. Cela peut être le cas notamment s’il existe un conflit
          entre les intérêts de l’Utilisateur et ceux de Legalify, de l’un de
          ses clients, employés, représentants ou auxiliaires ou d’un
          prestataire de services externe.
        </p>

        <h5>3. Obligations de l’Utilisateur</h5>
        <p>
          <span>3.1</span> L’Utilisateur s’engage à fournir à Legalify, en temps
          utile, les instructions, informations et documents nécessaires, qui
          sont réputés être exacts et complets, en vue de l’exécution des
          Services.
        </p>
        <p>
          <span>3.2</span> L’Utilisateur s’engage également à fournir à Legalify
          les informations nécessaires à la recherche de l’existence de conflits
          d’intérêts. Ces informations doivent être transmises sur demande de
          Legalify, de l’un de ses employés, représentants ou auxiliaires ou
          d’un prestataire de services externe.
        </p>
        <p>
          <span>3.3</span> Toutes les informations utiles en vue de l’exécution
          des Services doivent être portées à la connaissance de Legalify à
          brève échéance dès leur connaissance par l’Utilisateur. Il s’agit
          notamment des informations relatives à l’exécution des Services
          eux-mêmes ou pertinentes en matière de conflits d’intérêts.
        </p>
        <p>
          <span>3.4</span> L’Utilisateur s’engage à verser à Legalify ou aux
          prestataires de services externes, le cas échéant, les montants dus
          pour l’exécution des Services. Selon les Services, ces montants sont
          dus avant l’exécution de la prestation, en tout ou en partie, ou après
          celle-ci.
        </p>
        <p>
          <span>3.5</span> L’Utilisateur s’engage à prendre en charge tous les
          coûts liés à l’exécution des Services ou à rembourser à Legalify ou
          aux prestataires de services externes, le cas échéant, les avances et
          frais déboursés dans ce cadre, ainsi qu’à libérer ces derniers de
          toute éventuelle obligation contractée.
        </p>
        <h5>4. Constitutions de sociétés</h5>
        <p>
          <span>4.1</span> Dans le cadre notamment de la constitution de
          sociétés, d’inscriptions ou de modifications au registre du commerce,
          Legalify est libre de s’adjoindre les services de tout notaire breveté
          de son choix, lorsque cela est jugé utile ou nécessaire, selon sa
          propre appréciation.
        </p>
        <p>
          <span>4.2</span> Toute constitution de société, tout enregistrement
          d’entreprise individuelle, ainsi que toute inscription ou modification
          du registre du commerce, sont subordonnés au fait que l’Utilisateur
          transmette en temps voulu à Legalify, ses employés, ses représentants,
          ses auxiliaires ou à un prestataire de services externe, les
          instructions, informations et/ou documents nécessaires, exacts et
          complets.
        </p>
        <p>
          <span>4.3</span> Ni Legalify, ni ses employés, représentants ou
          auxiliaires, ni les prestataires de services externes, le cas échéant,
          n’assumeront de responsabilité lorsque l’Utilisateur ne transmet pas
          les instructions, informations et/ou documents nécessaires à
          l’exécution des Services ou transmet des instructions, informations
          et/ou documents erronés ou incomplets.
        </p>
        <p>
          <span>4.4</span> Les prix indiqués sur la Plateforme ou lors de la
          transmission d’un devis en relation avec le droit des sociétés,
          notamment la constitution de sociétés, l’enregistrement d’entreprises
          individuelles, les inscriptions ou les modifications du registre du
          commerce, comprennent uniquement les honoraires perçus par Legalify et
          par un notaire breveté, le cas échéant, à l’exclusion de tous autres
          coûts. Les émoluments de registre du commerce et les frais liés à
          l’ouverture d’un compte de consignation auprès d’un établissement
          bancaire suisse ne sont notamment pas compris dans les prix indiqués
          sur la Plateforme. Ces coûts, ainsi que tous autres coûts, sont à la
          charge de l’Utilisateur.
        </p>
        <p>
          <span>4.5</span> Legalify se réserve le droit de conserver les
          honoraires et frais encourus pour toute demande de constitution de
          société, d’enregistrement d’entreprise individuelle, d’inscription ou
          de modification du registre du commerce, lorsque l’Utilisateur ne
          transmet pas les instructions, informations et/ou documents
          nécessaires à l’exécution des Services ou transmet des instructions,
          informations et/ou documents erronés ou incomplets.
        </p>
        <h5>5. Contrats et documents juridiques </h5>
        <p>
          <span>5.1</span> Les documents juridiques et les contrats
          téléchargeables sur la Plateforme (ci-après : les{" "}
          <span className="fat">« Documents »</span>) constituent des documents
          rédigés en tenant compte des exigences légales applicables et du but
          spécifique de chaque Document. Toute modification de leur texte est
          susceptible d’en altérer l’exactitude, la conformité avec les
          dispositions légales applicables ou leur portée générale, ce que
          l’Utilisateur reconnaît expressément.
        </p>
        <p>
          <span>5.2</span> Seuls certains points des Documents, indiqués en tant
          que tel, doivent être complétés par l’Utilisateur, selon ses besoins
          et avec les informations dont lui seul dispose.
        </p>
        <p>
          <span>5.3</span> L’Utilisateur reconnaît expressément que toute
          modification du contenu des Documents, autre que celles visant à les
          compléter conformément au point n° 5.2, est effectuée sous sa
          responsabilité et à ses risques et périls et que ni Legalify, ni ses
          employés, représentants ou auxiliaires, ni les prestataires de
          services externes, le cas échéant, n’assument de responsabilité en
          pareil cas.
        </p>
        <p>
          <span>5.4</span> Les Documents peuvent, dans certains cas, ne pas être
          adaptés à toutes les situations juridiques ou nécessiter des
          compléments. C’est le cas, par exemple, du contrat de travail, pour
          lequel des clauses supplémentaires peuvent être nécessaires lorsque la
          profession à laquelle il se rapporte est protégée par une convention
          collective de travail. Dans de pareils cas, la Plateforme peut
          comporter un avertissement adéquat.
        </p>
        <p>
          <span>5.5</span> L’Utilisateur reconnaît expressément que certains
          Documents peuvent ne pas être adaptés à toutes les situations ou
          nécessiter des compléments et s’engage à prendre connaissance et à
          tenir compte des avertissements figurant sur la Plateforme.
        </p>
        <h5>6. Entretiens téléphoniques</h5>
        <p>
          <span>6.1</span> Lorsque l’Utilisateur requiert un entretien
          téléphonique par le biais de la Plateforme, il est tenu de fournir à
          Legalify toutes les informations nécessaires afin d’être contacté,
          soit notamment un numéro de téléphone et une adresse e-mail valables.
          À la date prévue, l’Utilisateur s’engage à être joignable au numéro de
          téléphone indiqué à Legalify, à l’heure à laquelle il a requis un
          entretien.
        </p>
        <p>
          <span>6.2</span> Legalify n’encourt aucune responsabilité si
          l’Utilisateur fournit des informations de contact incorrectes ou s’il
          n’est pas atteignable aux date et heure prévues pour un entretien.
          Dans de pareils cas, un nouvel entretien téléphonique pourra être fixé
          à une date ultérieure, selon les disponibilités de Legalify.
        </p>
        <p>
          <span>6.3</span> Legalify se réserve le droit de conserver les
          honoraires et frais encourus pour toute demande d’entretien
          téléphonique, lorsque l’Utilisateur ne transmet pas les informations
          nécessaires afin d’être contacté, transmet des informations
          incorrectes ou n’est pas atteignable aux date et heure prévues pour un
          entretien.
        </p>
        <h5>7. Entretiens personnels</h5>
        <p>
          <span>7.1</span> Lorsque l’Utilisateur requiert un entretien personnel
          par le biais de la Plateforme, il est tenu de fournir à Legalify
          toutes les informations nécessaires afin d’être contacté, soit
          notamment un numéro de téléphone et une adresse e-mail valables. À la
          date prévue, l’Utilisateur s’engage à se présenter dans les locaux de
          Legalify, à l’heure à laquelle il a requis un entretien.
        </p>
        <p>
          <span>7.2</span> Legalify n’encourt aucune responsabilité si
          l’Utilisateur a fourni des informations de contact incorrectes ou s’il
          ne se présente pas dans les locaux de Legalify aux date et heure
          prévues pour un entretien. Dans de pareils cas, un nouvel entretien
          personnel pourra être fixé à une date ultérieure, selon les
          disponibilités de Legalify.
        </p>
        <p>
          <span>7.3</span> Legalify se réserve le droit de conserver les
          honoraires et frais encourus pour toute demande d’entretien personnel,
          lorsque l’Utilisateur ne transmet pas les informations nécessaires
          afin d’être contacté, transmet des informations incorrectes ou ne se
          présente pas dans les locaux de Legalify aux date et heure prévues
          pour un entretien.
        </p>
        <h5>8. Abonnements juridiques</h5>
        <p>
          <span>8.1</span> Un abonnement juridique commandé par le biais de la
          Plateforme donne à l’Utilisateur le droit à un certain nombre d’heures
          de conseils et prestations juridiques par mois, selon le type
          d’abonnement choisi.
        </p>
        <p>
          <span>8.2</span> L’assistance et la représentation devant les
          autorités judiciaires civiles, administratives ou pénales ne sont, en
          principe, pas comprises dans les prestations fournies dans le cadre
          d’un abonnement juridique. Dans de pareils cas, ces prestations
          pourront faire l’objet d’un contrat séparé entre l’Utilisateur et
          Legalify ou un prestataire de services externe.
        </p>
        <p>
          <span>8.3</span> À la commande d’un abonnement juridique,
          l’Utilisateur s’engage à verser à Legalify, de manière mensuelle, le
          montant prévu par son abonnement. Ce montant doit être versé à la fin
          de chaque mois pour le mois suivant.
        </p>
        <p>
          <span>8.4</span> Les montants versés à Legalify dans le cadre d’un
          abonnement juridique ne sont pas remboursables. Les heures auxquelles
          a droit chaque mois l’Utilisateur en vertu de son abonnement sont
          reportées automatiquement sur le mois suivant si elles ne sont pas
          utilisées et s’additionnent aux heures de ce dernier. Si, de manière
          répétée, l’Utilisateur n’utilise pas les heures auxquelles il a droit
          selon son abonnement, Legalify se réserve le droit de lui suggérer un
          abonnement juridique moins étendu, potentiellement plus adapté à ses
          besoins.
        </p>
        <p>
          <span>8.5</span> Si l’Utilisateur dépasse le nombre d’heures
          auxquelles il a droit selon son abonnement juridique, les heures
          supplémentaires non comprises dans l’abonnement sont facturées à un
          tarif convenu entre l’Utilisateur et Legalify, en application des
          tarifs usuels applicables. Lorsque l’Utilisateur dépasse de manière
          répétée les heures auxquelles il a droit selon son abonnement
          juridique, Legalify se réserve le droit de lui suggérer un abonnement
          juridique plus étendu, potentiellement plus adapté à ses besoins.
        </p>
        <p>
          <span>8.6</span> Tout abonnement juridique peut être résilié pour la
          fin d’un mois par l’Utilisateur, moyennant un avertissement préalable
          par écrit à Legalify, au moins un mois en avance. Legalify peut
          résilier tout abonnement juridique en tout temps, selon sa propre
          appréciation.
        </p>
        <h5>9. Prix</h5>
        <p>
          <span>9.1</span> Les prix indiqués sur la Plateforme sont en francs
          suisses (CHF) et ne comprennent pas les taxes applicables, comme la
          taxe sur la valeur ajoutée (TVA), sauf mention contraire expresse.
        </p>
        <p>
          <span>9.2</span> Legalify se réserve le droit de modifier les prix
          indiqués sur la Plateforme en tout temps, selon sa propre
          appréciation.
        </p>
        <h5>10. Communications</h5>
        <p>
          <span>10.1</span> L’Utilisateur accepte que Legalify, ses employés,
          ses représentants, ses auxiliaires ou, le cas échéant, des
          prestataires de services externes, utilisent des moyens électroniques
          non cryptés dans le cadre de leurs communications. Il accepte
          notamment l’usage d’e-mails, d’applications, notamment de
          vidéocommunication, ou de fax et reconnaît que ces moyens peuvent
          comporter des risques, notamment que les communications soient
          interceptées ou reçues par des tiers, soient modifiées ou manipulées,
          ne soient pas reçues par leur destinataire ou soient reçues
          tardivement, soient infectées par un virus informatique ou corrompues
          d’une autre manière.
        </p>
        <p>
          <span>10.2</span> Legalify décline toute responsabilité pour les
          risques mentionnés au point n° 10.1.
        </p>
        <p>
          <span>10.3</span> L’Utilisateur admet expressément que les
          informations communiquées à Legalify dans le cadre des services et
          prestations fournies peuvent être enregistrées et conservées sous une
          ou plusieurs formes définies par Legalify, selon sa propre
          appréciation, et conservées pour une durée de dix ans, à la fin de
          laquelle Legalify est autorisée à les détruire.
        </p>
        <h5>11. Confidentialité</h5>
        <p>
          <span>11.1</span> Toutes les informations fournies par l’Utilisateur
          et celles acquises par Legalify dans le cadre de l’exécution de
          conseils ou de prestations juridiques sont confidentielles et cela
          même après que la relation entre l’Utilisateur et Legalify a pris fin.
        </p>
        <p>
          <span>11.2</span> Legalify est autorisée à divulguer des informations
          confidentielles aux conditions suivantes : <br />
          <span className="indent">
            a) la communication des informations confidentielles est nécessaire
            pour l’exercice d’un droit ou d’une obligation découlant des
            conseils ou des prestations à fournir par Legalify ;
          </span>
          <span className="indent">
            b) la communication des informations confidentielles est obligatoire
            en application de la loi ou d’une décision d’une autorité judiciaire
            ou administrative compétente ;
          </span>
          <span className="indent">
            c) les informations confidentielles sont publiques ou le sont
            devenues autrement qu’en raison d’une violation des obligations
            découlant des Conditions générales.
          </span>
        </p>
        <p>
          <span>11.3</span> La{" "}
          <Link to="/pdc">Politique de confidentialité</Link> indique de quelle
          manière et dans quel but Legalify traite les données collectées par le
          biais de la Plateforme. Elle est librement accessible sur la
          Plateforme.
        </p>
        <h5>12. Propriété intellectuelle</h5>
        <p>
          <span>12.1</span> Sauf mention contraire, tout le contenu présent sur
          la Plateforme appartient à Legalify et est protégé par le droit
          d’auteur ainsi que par les autres règles applicables en matière de
          propriété intellectuelle.
        </p>
        <p>
          <span>12.2</span> Toute commercialisation, reproduction, transmission,
          impression ou enregistrement d’un quelconque contenu (image,
          graphisme, information, document, texte, contrat-type, etc.) présent
          sur la Plateforme est interdit, sauf autorisation explicite et écrite
          de Legalify.
        </p>
        <p>
          <span>12.3</span> La commercialisation, la reproduction, la
          transmission, l’impression ou l’enregistrement d’un quelconque contenu
          (image, graphisme, information, document, texte, contrat-type, etc.)
          obtenu dans le cadre des Services est interdite, sauf autorisation
          explicite et écrite de Legalify.
        </p>
        <h5>13. Responsabilité</h5>
        <p>
          <span>13.1</span> Les informations figurant sur la Plateforme ne
          constituent en aucun cas un avis juridique et sont fournies à titre
          d’information uniquement. L’Utilisateur ne doit donc en aucun cas
          baser ses décisions sur ces informations.
        </p>
        <p>
          <span>13.2</span> D’une manière générale, Legalify s’efforce de faire
          figurer sur la Plateforme des informations correctes au moment de leur
          publication. Toutefois, Legalify décline toute responsabilité pour
          d’éventuelles erreurs ou omissions que pourraient présenter la
          Plateforme.
        </p>
        <p>
          <span>13.3</span> Legalify n’est en aucun cas responsable du contenu
          des sites auxquels la Plateforme renvoie. Toute référence à de tels
          sites est uniquement fournie à titre informatif.
        </p>
        <p>
          <span>13.4</span> Dans les limites de la loi, Legalify exclut toute
          responsabilité pour tout dommage direct, indirect ou consécutif
          au-delà du montant versé par l’Utilisateur pour une prestation.
        </p>
        <p>
          <span>13.5</span> Legalify n’est en aucun cas responsable pour le fait
          de ses auxiliaires.
        </p>
        <p>
          <span>13.6</span> La Plateforme et les informations qu’elle contient
          sont fournies selon l’appréciation de Legalify et aucun accès n’y est
          garanti. En conséquence, Legalify décline toute responsabilité pour
          les dommages résultant d’une impossibilité d’accéder à la Plateforme.
        </p>
        <p>
          <span>13.7</span> Legalify décline toute responsabilité concernant la
          protection des données lors de leur transfert via Internet, que ce
          soit lors d’un échange de courriels ou lors de l’accès à la
          Plateforme.
        </p>
        <h5>14. Nullité partielle</h5>
        <p>
          Si l’une des dispositions des Conditions générales est nulle, les
          autres dispositions restent valides.
        </p>
        <h5>15. Fin des relations</h5>
        <p>
          Les dispositions prévues aux points nos 10, 11, 12, 13, 14 et 16
          restent applicables même une fois les relations entre l’Utilisateur et
          Legalify terminées.
        </p>
        <h5>16. For et droit applicable</h5>
        <p>
          <span>16.1</span> La relation entre l’Utilisateur et Legalify est
          soumise exclusivement au droit suisse.
        </p>
        <p>
          <span>16.2</span> Tout litige relatif aux Conditions générales, à la
          <Link to="/pdc"> Politique de confidentialité</Link> ou à la relation
          entre l’Utilisateur et Legalify sera soumis à la compétence exclusive
          des tribunaux du Canton de Genève, le recours au Tribunal fédéral
          étant réservé.
        </p>
      </div>
    </Layout>
  );
};

export default Cg;
